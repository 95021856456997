<template>
  <footer class="catalog-footer" v-if="resellerContact">
    <img src="@/assets/img/logo-black.svg" alt="" class="catalog-footer-whiz-logo">
    <hr>
    <div class="d-flex flex-column flex-grow-1 mt-3">
      <div v-if="resellerContact.name">Name : {{ resellerContact.name }}</div>
      <div v-if="resellerContact.email" class="mt-1">Email : <a :href="`mailto:${this.resellerContact.email}`">{{ resellerContact.email }}</a></div>
      <div v-if="resellerContact.phone" class="mt-1">Whatsapp : <a :href="`https://wa.me/${resellerContact.phone}`" target="_blank">{{ resellerContact.phone }}</a></div>
    </div>
    <div class="mt-3">Copyright &copy; {{ new Date().getUTCFullYear() }} Whizliz.com</div>
  </footer>
</template>

<script>
import { setMetainfo } from "@/_helpers";

export default {
  name: "CatalogFooter",
  data() {
    return {
      resellerContact: {},
    }
  },
  mounted() {
    this.getResellerContact();
  },
  methods: {
    async getResellerContact() {
      try {
        const id = this.$route.params.slug.split("-").pop();
        const res = await this.$api.reseller.checkReseller(id);
        if (res.status === 200) {
          this.resellerContact = res.data.data
          this.$emit("setResellerContact", res.data.data)
          // Update document title if reseller name exist
          if (this.resellerContact?.name != null) document.title = `Whizliz Jewelry & Gold Catalogue - @${this.resellerContact.name} Official Partner`
        } 
      } catch (error) {
        console.error(error)
      }
    },
  },
  created() {
    document.title = "Whizliz Jewelry & Gold Catalogue";
  },
  metaInfo() {
    return setMetainfo(
      this.resellerContact?.name != null
        ? `Whizliz Jewelry & Gold Catalogue - @${this.resellerContact.name} Official Partner`
        : null,
      "Temukan beragam koleksi perhiasan & logam mulia dengan harga spesial di sini. Tersedia banyak pilihan kalung emas, cincin berlian, gelang emas, anting berlian"
    );
  },
}
</script>